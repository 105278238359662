<template>
	<div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading">
	  <div class="caption">
		<p>{{ doctorTypeTitle }}</p>
		<el-dropdown trigger="click" v-if="is_self == '1'">
		  <span class="el-dropdown-link">
			...
		  </span>
		  <el-dropdown-menu slot="dropdown">
			<el-dropdown-item @click.native="review">审核</el-dropdown-item>
			<el-dropdown-item @click.native="physicianTermination(1)"
			  >解约</el-dropdown-item
			>
			<el-dropdown-item @click.native="physicianTermination(2)"
			  >删除</el-dropdown-item
			>
		  </el-dropdown-menu>
		</el-dropdown>
		<el-dialog
		  title="审核设置"
		  :append-to-body="true"
		  :visible.sync="reviewed"
		>
		  <div class="edit-dialog">
			<div class="flex">
			  <div
				class="flex"
				style="height: 30px;margin-left: 10px;font-weight: 400;"
			  >
				<span>通过</span>
				<div
				  :class="passReview == 'Y' ? 'pass-review' : ''"
				  class="review"
				  @click="passReviewed('Y')"
				></div>
			  </div>
			  <div
				class="flex"
				style="height: 30px;margin-left: 30px;font-weight: 400;"
			  >
				<span>拒绝</span>
				<div
				  :class="passReview == 'N' ? 'pass-review' : ''"
				  class="review"
				  @click="passReviewed('N')"
				></div>
			  </div>
			</div>
			<div>
			  <span
				style="font-size: 14px;font-weight: bold;margin-left: 10px;color: #606266;"
				>拒绝原因</span
			  >
			  <el-input
				:disabled="passReview == 'Y'"
				type="textarea"
				v-model="denialReason"
				style="margin-top: 10px;"
			  ></el-input>
			</div>
		  </div>
		  <div slot="footer" class="dialog-footer">
			<el-button @click="reviewed = false" class="btn-padding"
			  >取 消</el-button
			>
			<el-button type="primary" @click="saveReviewed" class="btn-padding"
			  >审核</el-button
			>
		  </div>
		</el-dialog>
	  </div>
	  <div class="header">
		<div class="title">
		  <div
			class=""
			style="position: relative;border-radius: 50%;"
			@mouseover="mouseOver"
			@mouseleave="mouseLeave"
			v-loading="avatar_loading"
		  >
			<el-image
			  class="avatars"
			  :class="mouseOverImg ? 'mouseOverAvatar' : ''"
			  :src="info.basic_info.basic ? info.basic_info.basic.head_pic : ''"
			>
			</el-image>
			<el-upload
			  class="avatar-uploader"
			  :data="qiniuData"
			  :action="actionPath + id"
			  :show-file-list="false"
			  name="file"
			  :headers="header"
			  :on-success="uploadDocPhotoSuccess"
			  :before-upload="beforeAvatarUpload"
			>
			  <img
				v-if="mouseOverImg"
				src="../../assets/camera.png"
				class="mouseOver"
			  />
			</el-upload>
		  </div>
		  <div class="title-detail">
			<div class="title-name">
			  <span>{{ info.basic_info.basic.doctor_name }}</span>
			</div>
			<div class="title-dep">
			  <img
				:src="
				  info.basic_info.basic.ge_name == '男'
					? require('../../assets/man_icon@3x.png')
					: require('../../assets/girl_icon@3x.png')
				"
				style="width: 14px;height: 14px;"
			  />
			  <div class="flex">
				<div class="title-dep-l">
				  <img src="../../assets/hospital1.png" style="width: 14px;" />
				  <span>{{ info.basic_info.basic.work_inst_name }}</span>
				</div>
				<div
				  class="title-dep-r"
				  v-if="info.basic_info.basic.doc_dept_name"
				>
				  <img src="../../assets/hospital2.png" style="width: 14px;" />
				  <span>{{ info.basic_info.basic.doc_dept_name }}</span>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	  <div class="gg-grid">
		<el-tabs v-model="activeTab" height="250">
		  <el-tab-pane label="基础信息" name="first">
			<div class="information">
			  <div class="information-title">
				<div class="information-title-l">
				  <div class="required"></div>
				  <span>基础信息</span>
				</div>
				<div
				  class="edit"
				  @click="edit('基础信息')"
				  style="position: relative;"
				>
				  <span class="edit" style="color: #2384fc;margin-left: 10px;"
					>编辑</span
				  >
				</div>
			  </div>
			  <div class="information-info">
				<div class="information-info-l">
				  <div class="">
					<span>姓名</span
					><span>{{ info.basic_info.basic.doctor_name }}</span>
				  </div>
				  <div class="">
					<span>手机号码</span
					><span>{{ info.basic_info.basic.doc_tel }}</span>
				  </div>
				  <div class="">
					<span>身份证号</span
					><span>{{ info.basic_info.basic.id_card }}</span>
				  </div>
				 
				  <div class="">
					<span>省市区</span
					><span>{{ info.basic_info.basic.addr_name }}</span>
				  </div>
				
				 
				</div>
				<div class="information-info-l">
				  <div class="">
					<span>性别</span
					><span>{{ info.basic_info.basic.ge_name }}</span>
				  </div>
				  <div class="">
					<span>第一执业机构</span
					><span>{{ info.basic_info.basic.organization_name }}</span>
				  </div>
				  
				</div>
			  </div>
			</div>
			<div class="information">
			  <div class="information-title">
				<div class="information-title-l">
				  <div class="required"></div>
				  <span>证件</span>
				</div>
			  </div>
			  <div class="information-info">
				<div class="information-info-l">
				  <div class="">
					<span>执业证号</span
					><span>{{ info.basic_info.basic.prac_no }}</span>
				  </div>
				 
				  <div class="">
					<span>执业起止日期</span
					><span>{{ info.basic_info.basic.doc_multi_sited_date[0] }} - {{ info.basic_info.basic.doc_multi_sited_date[1] }}</span>
				  </div>
  
				  <div class="">
					<span>职称</span
					><span>{{ info.basic_info.basic.title_name }}</span>
				  </div>
				
				</div>
			
			  </div>
			</div>
		  </el-tab-pane>
		  <!-- <el-tab-pane label="证件材料" name="second">
			<el-row>
            <el-col>
              <el-col :lg="12">
                <div class="information-title">
                  <div class="information-title-l">
                    <div class="required"></div>
                    <span>医师执业注册申请审核表</span>
                  </div>
                </div>
                <el-col :lg="12">
                  <div class="upload-img" v-if="!editImg">
                    <div class="" v-if="info.basic_info.basic.prac_apply">
                      <el-image
                        :z-index="100000"
                        :preview-src-list="[info.basic_info.basic.prac_apply]"
                        :src="info.basic_info.basic.prac_apply"
                        style="width: 220px;height: 110px;"
                      >
                      </el-image>
  
                    </div>
                    <div class="" v-else>
                      <img
                        src="../../assets/idcard_1e.png"
                        style="width: 30px;"
                      />
                      <p>医师执业注册申请审核表</p>
                    </div>
                  </div>
                  <div class="upload-img-edit" v-else>
                    <div class="" v-if="info.basic_info.basic.prac_apply">
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadApply"
                        v-loading="apply_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'apply')
                        "
                      >
                        <el-image
                          :src="info.basic_info.basic.prac_apply"
                          style="width: 220px;height: 110px;"
                        >
                        </el-image>
                      </el-upload>
                    </div>
                    <div class="" v-else>
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadApply"
                        v-loading="apply_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'apply')
                        "
                      >
                        <img
                          src="../../assets/camera.png"
                          class="positionImg"
                        />
                        <img
                          src="../../assets/idcard_1e.png"
                          style="width: 30px;"
                        />
                        <p>医师执业注册申请审核表</p>
                      </el-upload>
                    </div>
                  </div>
                </el-col>
              </el-col>
              <el-col :lg="12">
                <div class="information-title">
                  <div class="information-title-l">
                    <div class="required"></div>
                    <span>合同</span>
                  </div>
                  <div
                    class="edit"
                    style="position: relative;"
                    @click="editImg = !editImg"
                  >
                    <span style="color: #2384fc;">{{
                      editImg ? "预览" : "编辑"
                    }}</span>
                  </div>
                </div>
                <el-col class="dashboard-container-col" :lg="12">
                  <div class="upload-img" v-if="!editImg">
                    <div class="" v-if="info.basic_info.basic.employ_files_list[0]">
                      <el-image
                        :z-index="100000"
                        :preview-src-list="[
                          info.basic_info.basic.employ_files_list[0],
                        ]"
                        :src="info.basic_info.basic.employ_files_list[0]"
                        style="width: 220px;height: 110px;"
                      >
                      </el-image>
        
                    </div>
                    <div class="" v-else>
                      <img
                        src="../../assets/national-emblem.png"
                        style="width: 30px;"
                      />
                      <p>合同</p>
                    </div>
                  </div>
                  <div class="upload-img-edit" v-else>
                    <div class="" v-if="info.basic_info.basic.employ_files_list[0]">
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadHetongASuccess"
                        v-loading="contract_a_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'contract_a')
                        "
                      >
                        <el-image
                          :src="info.basic_info.basic.employ_files_list[0]"
                          style="width: 220px;height: 110px;"
                        >
                        </el-image>
                      </el-upload>
                    </div>
                    <div class="" v-else>
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadHetongASuccess"
                        v-loading="contract_a_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'contract_a')
                        "
                      >
                        <img
                          src="../../assets/camera.png"
                          class="positionImg"
                        />
                        <img
                          src="../../assets/national-emblem.png"
                          style="width: 30px;"
                        />
                        <p>合同</p>
                      </el-upload>
                    </div>
                  </div>
                </el-col>
                <el-col class="dashboard-container-col" :lg="12">
                  <div class="upload-img" v-if="!editImg">
                    <div class="" v-if="info.basic_info.basic.employ_files_list[1]">
                      <el-image
                        :z-index="100000"
                        :preview-src-list="[
                          info.basic_info.basic.employ_files_list[1],
                        ]"
                        :src="info.basic_info.basic.employ_files_list[1]"
                        style="width: 220px;height: 110px;"
                      >
                      </el-image>
        
                    </div>
                    <div class="" v-else>
                      <img
                        src="../../assets/national-emblem.png"
                        style="width: 30px;"
                      />
                      <p>合同</p>
                    </div>
                  </div>
                  <div class="upload-img-edit" v-else>
                    <div class="" v-if="info.basic_info.basic.employ_files_list[1]">
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadHetongBSuccess"
                        v-loading="contract_b_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'contract_b')
                        "
                      >
                        <el-image
                          :src="info.basic_info.basic.employ_files_list[1]"
                          style="width: 220px;height: 110px;"
                        >
                        </el-image>
                      </el-upload>
                    </div>
                    <div class="" v-else>
                      <el-upload
                        :data="qiniuData"
                        :action="materialPath + id"
                        :show-file-list="false"
                        name="file"
                        :headers="header"
                        :on-success="uploadHetongBSuccess"
                        v-loading="contract_b_loading"
                        element-loading-text="上传中"
                        :before-upload="
                          (file) => beforeAvatarUpload(file, 'contract_b')
                        "
                      >
                        <img
                          src="../../assets/camera.png"
                          class="positionImg"
                        />
                        <img
                          src="../../assets/national-emblem.png"
                          style="width: 30px;"
                        />
                        <p>合同</p>
                      </el-upload>
                    </div>
                  </div>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
		  </el-tab-pane> -->
		  <el-tab-pane
			:label="doctorTypeNav"
			name="third"
			v-if="info.diag_setting"
		  >
			<div class="" v-if="doctorType == 1">
			  <el-row>
				<el-col :lg="24">
				  <div class="information-title">
					<div class="information-title-l">
					  <div class="required"></div>
					  <span>擅长及简介</span>
					</div>
					<div
					  class="edit"
					  style="position: relative;"
					  @click="openSetUp"
					>
					  <span style="color: #2384fc;">编辑</span>
					</div>
				  </div>
				  <div class="information-info">
					<div class="information-info-l" style="width: 100%;">
					  <div class="">
						<span>科室</span
						><span>{{ info.diag_setting.expert.doc_dept_name }}</span>
					  </div>
					  <div class="">
						<span>擅长疾病</span
						><span>{{
						  info.diag_setting.expert.professional_name
						}}</span>
					  </div>
					  <div class="">
						<span>个人简介</span
						><span>{{ info.diag_setting.expert.doc_comment }}</span>
					  </div>
					</div>
				  </div>
				</el-col>
			  </el-row>
			  <!--						<el-row>
							  <el-col :lg="24">
								  <div class="information-title ">
									  <div class="information-title-l">
										  <div class="required">
										  </div>
										  <span>问诊价格</span>
									  </div>
								  </div>
								  <div class="information-info">
									  <div class="information-info-l  m-t-10">
										  <span>义诊</span>
										  <span> {{info.diag_setting.setting.free_status==0?'关':'开'}}</span>
									  </div>
								  </div>
								  <div class="inquiry">
									  <div class="inquiryImg m-t-10">
										  <img src="../../assets/graphic.png">
										  <span>图文复诊</span>
									  </div>
									  <div class="inquiryText m-t-10">
										  <span>价格</span>
										  <span>¥{{info.diag_setting.setting.pic_price}}</span>
										  <span>接单上限</span>
										  <span>{{info.diag_setting.setting.pic_num}}</span>
									  </div>
								  </div>
								  <div class="inquiry">
									  <div class="inquiryImg m-t-10">
										  <img src="../../assets/video.png">
										  <span>视频复诊</span>
									  </div>
									  <div class="inquiryText m-t-10">
										  <span>价格</span>
										  <span>¥{{info.diag_setting.setting.video_price}}</span>
										  <span>接单上限</span>
										  <span>{{info.diag_setting.setting.video_num}}</span>
									  </div>
								  </div>
							  </el-col>
						  </el-row>-->
			</div>
			<div class="" v-else>
			  <el-row>
				<el-col :lg="24">
				  <div class="information-title">
					<div class="information-title-l">
					  <div class="required"></div>
					  <span>简介</span>
					</div>
					<div
					  class="edit"
					  style="position: relative;"
					  @click="openSetUp"
					>
					  <span style="color: #2384fc;">编辑</span>
					</div>
				  </div>
				  <div class="information-info">
					<div class="information-info-l" style="width: 100%;">
					  <div class="">
						<span>个人简介</span
						><span>{{ info.diag_setting.expert.doc_comment }}</span>
					  </div>
					</div>
				  </div>
				</el-col>
			  </el-row>
			</div>
			<el-dialog
			  :title="doctorType == 1 ? '编辑就诊设置' : ''"
			  :append-to-body="true"
			  :modal="true"
			  :visible.sync="setUp"
			>
			  <el-form
				:model="diag_setting.expert"
				:rules="rules2"
				:show-message="false"
				:status-icon="true"
				ref="form2"
			  >
				<div class="edit-dialog" v-if="doctorType == 1">
				  <div class="edit-frame">
					<span class="require">科室</span>
					<el-form-item prop="depart_id">
					  <el-cascader
						v-model="diag_setting.expert.depart_id"
						style="width: 80%;"
						size="small"
						:options="departSymptom"
						@change="departChange"
						:props="{ checkStrictly: true }"
					  >
					  </el-cascader>
					  <!-- <el-select style="width: 80%;" v-model="diag_setting.expert.depart_id" clearable
											  placeholder="请选择" size="small" @change="departChange">
											  <el-option v-for="(item,index) in departSymptom.departs" :key="index"
												  :label="item.depart_name" :value="item.id">
											  </el-option>
										  </el-select> -->
					</el-form-item>
				  </div>
				  <div class="">
					<span class="require">擅长疾病</span>
					<el-form-item prop="professional_name">
					  <el-select
						clearable
						multiple
						style="width: 80%;"
						v-model="diag_setting.expert.professional"
						placeholder="请选择"
						size="small"
					  >
						<el-option
						  v-for="(item, index) of symptomLists"
						  :key="index"
						  :label="item.symptom_name"
						  :value="item.id"
						>
						</el-option>
					  </el-select>
					</el-form-item>
				  </div>
				  <div class="">
					<span class="require">个人简介</span>
					<el-form-item prop="doc_comment">
					  <el-input
						type="textarea"
						style="margin-top: 10px;"
						:autosize="true"
						v-model="diag_setting.expert.doc_comment"
					  ></el-input>
					</el-form-item>
				  </div>
				  <div class="">
					<span class="require" style="margin-bottom: 8px;">义诊</span>
					<el-switch
					  class="switch"
					  v-model="diag_setting.setting.free_status"
					  :active-value="1"
					  :inactive-value="0"
					  active-text="开"
					  inactive-text="关"
					>
					</el-switch>
				  </div>
				  <div>
					<div class="flex">
					  <span class="require">图文状态设置</span>
					  <el-switch
						style="margin-left: 6px;margin-top: 5px;"
						class="switch"
						v-model="diag_setting.setting.pic_status"
						:active-value="1"
						:inactive-value="0"
						active-text="开"
						inactive-text="关"
					  >
					  </el-switch>
					</div>
				  </div>
				  <div class="flex">
					<div class="w-50">
					  <span
						class="inb"
						style="margin-bottom: 8px;margin-top: 5px;"
						>复诊价格 (单位: ¥)</span
					  >
  
					  <el-input
						v-model="diag_setting.setting.pic_price"
						size="small"
						class="ipt"
						placeholder="请输入内容"
					  />
					</div>
					<div class="w-50">
					  <span
						class="inb"
						style="margin-bottom: 8px;margin-top: 5px;display: block;"
						>接单上限</span
					  >
  
					  <el-input
						v-model="diag_setting.setting.pic_num"
						size="small"
						class="ipt"
						placeholder="请输入内容"
					  />
					</div>
				  </div>
				  <div>
					<div class="flex">
					  <span class="require">视频状态设置</span>
					  <el-switch
						style="margin-left: 6px;"
						class="switch"
						v-model="diag_setting.setting.video_status"
						:active-value="1"
						:inactive-value="0"
						active-text="开"
						inactive-text="关"
					  >
					  </el-switch>
					</div>
				  </div>
				  <div class="flex">
					<div class="w-50">
					  <span
						style="margin-bottom: 8px;margin-top: 5px;"
						class="inb"
						>复诊价格 (单位: ¥)</span
					  >
					  <el-input
						v-model="diag_setting.setting.video_price"
						size="small"
						class="ipt"
						placeholder="请输入内容"
					  />
					</div>
					<div class="w-50">
					  <span
						class="inb"
						style="margin-bottom: 8px;margin-top: 5px;display: block;"
						>接单上限</span
					  >
  
					  <el-input
						v-model="diag_setting.setting.video_num"
						size="small"
						class="ipt"
						placeholder="请输入内容"
					  />
					</div>
				  </div>
				</div>
				<div v-else>
				  <span class="require">个人简介</span>
				  <el-form-item prop="doc_comment">
					<el-input
					  type="textarea"
					  style="margin-top: 10px;"
					  :autosize="true"
					  v-model="diag_setting.expert.doc_comment"
					></el-input>
				  </el-form-item>
				</div>
			  </el-form>
			  <div slot="footer" class="dialog-footer">
				<el-button @click="setUp = false" class="btn-padding"
				  >取 消</el-button
				>
				<el-button type="primary" @click="saveSet" class="btn-padding"
				  >保 存</el-button
				>
			  </div>
			</el-dialog>
		  </el-tab-pane>
		  <el-tab-pane label="操作记录" name="fourth">
			<div class="timeline">
			  <el-timeline>
				<el-timeline-item
				  v-for="(item, index) of info.record"
				  :key="index"
				  type="primary"
				  size="large"
				>
				  <!-- 时间线左侧 -->
				  <div class="companydel">
					{{ item.created_at }}
					<p>{{ item.w ? "星期" : "" }}{{ item.w }}</p>
				  </div>
				  <!-- 时间线右侧 -->
				  <div>
					<div class="el-row">
					  <span>{{ item.time }}</span>
					  <span v-if="item.admin">{{ item.admin.name }}</span>
					  <span>{{
						item.status == 1 ? "医生审核通过" : "医生审核未通过"
					  }}</span>
					  <span v-if="item.status == 0"
						>拒绝原因: {{ item.remark }}</span
					  >
					</div>
				  </div>
				</el-timeline-item>
			  </el-timeline>
			</div>
		  </el-tab-pane>
		</el-tabs>
	  </div>
	  <el-dialog
		:title="editTitle"
		:append-to-body="true"
		:modal="true"
		:visible.sync="dialogFormVisible"
	  >
		<div class="edit-dialog">
		  <el-form
			:model="basic_info.basic"
			:rules="rules"
			:show-message="false"
			:status-icon="true"
			ref="form1"
		  >
			<el-row>
			  <el-col :lg="12">
				<div class="edit-frame">
				  <span class="require">姓名</span>
				  <el-form-item prop="doctor_name">
					<el-input
					  v-model="basic_info.basic.doctor_name"
					  size="small"
					  class="ipt"
					  placeholder="请输入内容"
					>
					</el-input>
				  </el-form-item>
				</div>
			</el-col> 
			<el-col :lg="12">
				<div class="edit-frame">
				  <span class="require">手机号</span>
				  <el-form-item prop="doc_tel">
					<el-input
					  v-model="basic_info.basic.doc_tel"
					  size="small"
					  class="ipt"
					  placeholder="请输入内容"
					>
					</el-input>
				  </el-form-item>
				</div>
			</el-col>
		</el-row>
		<el-row>
			<el-col :lg="12">
				<div class="edit-frame">
				  <span class="require">身份证号</span>
				  <el-form-item prop="id_card">
					<el-input
					  v-model="basic_info.basic.id_card"
					  size="small"
					  class="ipt"
					  placeholder="请输入内容"
					>
					</el-input>
				  </el-form-item>
				</div>
			</el-col> 
			<el-col :lg="12">
				<div class="edit-frame">
				  <span class="require" style="display: block;">省市区</span>
				  <el-form-item prop="district_code">
					<el-cascader
					  v-model="basic_info.basic.district_code"
					  style="width: 80%;"
					  size="small"
					  :options="pcaOptions"
					  @change="handleChange"
					>
					</el-cascader>
				  </el-form-item>
				</div>
			  </el-col>
			</el-row>
			<el-row>
			  <el-col :lg="12">
				<div class="edit-frame">
				  <span class="require" style="display: block;">性别</span>
				  <el-form-item prop="ge_code">
					<el-select
					  style="width: 80%;"
					  v-model="basic_info.basic.ge_code"
					  clearable
					  placeholder="请选择"
					  size="small"
					>
					  <el-option
						v-for="item in genderOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					  >
					  </el-option>
					</el-select>
				  </el-form-item>
				</div>
			</el-col> 
			<el-col :lg="12">
				<div class="edit-frame">
				  <span class="title_label">第一执业机构 </span>
				  <el-form-item>
					<el-select
					  style="width: 80%;"
					  v-model="basic_info.basic.organization_name"
					  size="mini"
					  filterable
					  remote
					  reserve-keyword
					  placeholder="请输入关键词"
					  @change="orgSelectChange"
					  :remote-method="remoteMethod"
					  :loading="selectLoading"
					>
					  <el-option
						v-for="item in orgOptions"
						:key="item.org_id"
						:label="item.org_name"
						:value="item"
					  >
					  </el-option>
					</el-select>
				  </el-form-item>
				</div>
			</el-col> 
			</el-row>
			<el-row>
			<el-col :lg="12">
				
			
				<div class="edit-frame">
				  <span class="require">执业证号</span>
				  <el-form-item prop="prac_no">
					<el-input
					  v-model="basic_info.basic.prac_no"
					  size="small"
					  class="ipt"
					  placeholder="请输入内容"
					>
					</el-input>
				  </el-form-item>
				</div>
			</el-col> 
			<el-col :lg="12">
				<div class="edit-frame">
				  <span class="require">执业起止日期</span>
				  <el-form-item prop="prac_rec_date">
					<el-date-picker
					  value-format="yyyy-MM-dd"
					  v-model="basic_info.basic.doc_multi_sited_date"
					  class="ipt"
					  size="small"
					  range-separator="至"
					  start-placeholder="开始日期"
					  end-placeholder="结束日期"				
					  type="daterange"
					>
					</el-date-picker>
				  </el-form-item>
				</div>
			</el-col> 
		</el-row>
			<el-row>
			<el-col :lg="12">
				  <span class="require" style="display: block;">职称</span>
				  <el-form-item prop="title_code">
					<el-select
					  style="width: 80%;"
					  v-model="basic_info.basic.title_code"
					  clearable
					  placeholder="请选择"
					  size="small"
					>
					  <el-option
						v-for="item in titleOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					  >
					  </el-option>
					</el-select>
				  </el-form-item>
		
				
			  </el-col>
			</el-row>
		  </el-form>
		</div>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="dialogFormVisible = false" class="btn-padding"
			>取 消</el-button
		  >
		  <el-button type="primary" @click="informationSave" class="btn-padding"
			>保 存</el-button
		  >
		</div>
	  </el-dialog>
	  <!-- <el-button @click="dialogTableVisible=false" class="close-drawer" v-show="dialogTableVisible">
		  </el-button> -->
	</div>
  </template>
  
  <script>
  import { regionData } from "element-china-area-data";
  import { mapState } from "vuex";
  import { getToken } from "@/utils/auth";
  import { callMethod, getOrgList, getQNToken } from "@/api/basic";
  import config from "@/utils/config";
  import globalData from "@/utils/globalData.js";
  import {
	getDoctorsDetails,
	updateBasicInfo,
	admissionsSettings,
	updateWork,
	updateIntroduction,
	updatePunishment,
	saveReviewed,
	physicianTermination,
  } from "@/api/audit/doctor2.0.js";
  import { Message } from "element-ui";
  
  export default {
	name: "physicianDetail",
	props: {
	  type: {
		type: String,
		default: "add",
	  },
	  doctorType: {
		type: [Number, String],
		default: 1,
	  },
	  status: {
		type: [Number, String],
		default: 1,
	  },
	  info: {
		type: Object,
	  },
	},
	components: {},
	data() {
	  return {
		genderOptions:globalData.genderOptions,
		titleOptions:globalData.titleOptions,
		id:null,
		symptomLists: [],
		denialReason: "",
		workIndex: "", //编辑个人工作经验索引
		employmentUnit: {
		  authenticator: "",
		  company: "",
		  depart_name: "",
		  department_id: "",
		  duration: [],
		  title_id: "",
		  title_name: "",
		  depart_id: [],
		},
		avatar_loading: false,
		id_front_loading: false,
		id_back_loading: false,
		cert_no_loading: false,
		cert_info_loading: false,
		prac_no_loading: false,
		prac_info_loading: false,
		title_no_loading: false,
		title_info_loading: false,
		contract_a_loading: false,
		contract_b_loading: false,
		graduate_cert_loading: false,
		degree_cert_loading: false,
  
		basic_info: {},
		diag_setting: {},
		newWork: "",
		mouseOverImg: false,
		enterAvatars: false,
		editImg: false,
		drawer: false,
		price: 0,
		header: {
		  Authorization: "Bearer " + getToken(),
		},
		sanction: [],
		value: true,
		setUp: false, // 就诊设置
		passReview: "Y",
		reviewed: false, //审核框
		punishText: "", //惩罚文本
		addType: "",
		addWork: false, //工作经历
		dialogFormVisible: false, //弹出编辑框开关
		editTitle: "", //弹出编辑框标题
		admin_type: 0,
		actionPath: "/admin/doc/uploadDoctorPic/",
		materialPath: "/admin/doc/updateMaterial/",
		qiniuUrl: config.qiniuUrl,
		durationDate: [],
  
		pcaOptions: regionData,
		qiniuData: {
		  type: "",
		},
		radio: "1",
		fullscreenLoading: false,
		activeTab: "first",
		ruleForm: {
		  info: {},
		},
		eduList: [
		  // 中专、专科、本科、硕士研究生、博士研究生
		  {
			value: "中专",
			label: "中专",
		  },
		  {
			value: "专科",
			label: "专科",
		  },
		  {
			value: "本科",
			label: "本科",
		  },
		  {
			value: "硕士研究生",
			label: "硕士研究生",
		  },
		  {
			value: "博士研究生",
			label: "博士研究生",
		  },
		],
		rules3: {
		  company: [
			{
			  required: true,
			  message: "请输入就职单位",
			  trigger: "blur",
			},
		  ],
		  department_id: [
			{
			  required: true,
			  message: "请选择科室",
			  trigger: "change",
			},
		  ],
		  authenticator: [
			{
			  required: true,
			  message: "请填写证明人",
			  trigger: "blur",
			},
		  ],
		  duration: [
			{
			  required: true,
			  message: "请选择就职时间",
			  trigger: "change",
			},
		  ],
		  title_id: [
			{
			  required: true,
			  message: "请输入职称",
			  trigger: "change",
			},
		  ],
		},
		rules2: {
		  depart_id: [
			{
			  required: true,
			  message: "请选择科室",
			  trigger: "change",
			},
		  ],
		  professional_name: [
			{
			  required: true,
			  message: "请选择擅长疾病",
			  trigger: "change",
			},
		  ],
		  doc_comment: [
			{
			  required: true,
			  message: "请输入个人简介",
			  trigger: "blur",
			},
		  ],
		},
		rules: {
			doctor_name: [
			{
			  required: true,
			  message: "请输入姓名",
			  trigger: "blur",
			},
		  ],
		  doc_tel: [
			{
			  required: true,
			  message: "请输入手机号",
			  trigger: "blur",
			},
		  ],
		  id_card: [
			{
			  required: true,
			  message: "请输入身份证号",
			  trigger: "blur",
			},
		  ],
		
		  district_code: [
			{
			  required: true,
			  message: "请选择省市区",
			  trigger: "change",
			},
		  ],
		
		  doc_multi_sited_date: [
			{
			  required: true,
			  message: "请选择执业起止日期",
			  trigger: "change",
			},
		  ],
		  ge_code: [
			{
			  required: true,
			  message: "请选择性别",
			  trigger: "change",
			},
		  ],
		  organization_name: [
			{
			  required: true,
			  message: "请输入执业机构",
			  trigger: "blur",
			},
		  ],
		  
		  prac_no: [
			{
			  required: true,
			  message: "请输入执业证号",
			  trigger: "blur",
			},
		  ],
		  prac_type: [
			{
			  required: true,
			  message: "请选择执业类别",
			  trigger: "change",
			},
		  ],

		  title_code: [
			{
			  required: true,
			  message: "请输入职称名称",
			  trigger: "change",
			},
		  ],
		},
		selectLoading: false,
		orgOptions: [],
	  };
	},
	computed: {
	  ...mapState({
		doctorDic: (state) => state.dictionary.doctorDic, //字典
		departSymptom: (state) => state.dictionary.departDic, //字典
		symptomList: (state) => state.dictionary.departSymptom,
		is_self: (state) => state.user.is_self,
	  }),
	  doctorTypeTitle() {
		return (
		  {
			1: "医师信息",
			2: "药师信息",
		  }[this.doctorType] || "医生"
		);
	  },
	  doctorTypeNav() {
		return this.doctorType == 1 ? "就诊设置" : "个人简介";
	  },
	},
	mounted() {
	  this.$store.dispatch("dictionary/getDicData", [
		{
		  stateName: "doctorDic",
		  api: "/admin/hospital/getDicts",
		},
	  ]);
	  this.$store.dispatch("dictionary/getDicData", [
		{
		  stateName: "departDic",
		  api: "/admin/hospital/getDeparts",
		},
	  ]);
	  this.$store.dispatch("dictionary/getDicData", [
		{
		  stateName: "departSymptom",
		  api: "/admin/setting/getDepartSymptomSelect",
		},
	  ]);
	},
	created() {
	  this.admin_type = this.$store.state.user.userType;
	  this.getToken();
	  // this.info.basic_info.basic.prac_scope_approval[0] = "330"
  
	
  
	  if (!this.info.basic_info.basic.employ_files_list) {
		this.info.material.employ_files_list = {};
	  }
	  // if (!this.info.basic_info.basic.cert_expire_date) {
	  // 	this.info.basic_info.basic.cert_expire_date = ''
	  // }
	 
	  if (!this.info.diag_setting.setting.pic_num) {
		this.info.diag_setting.setting.pic_num = "0";
	  }
	  if (!this.info.diag_setting.setting.video_num) {
		this.info.diag_setting.setting.video_num = "0";
	  }
	  if (!this.info.diag_setting.setting.video_price) {
		this.info.diag_setting.setting.video_price = "0";
	  }
  
	  if (!this.info.diag_setting.setting.pic_price) {
		this.info.diag_setting.setting.pic_price = "0";
	  }
	  if (!this.info.basic_info.basic.title_rec_date) {
		this.info.basic_info.basic.title_rec_date = "";
	  }
	  this.basic_info = this.clone(this.info.basic_info);
	  console.log(this.basic_info);
	  this.diag_setting = this.clone(this.info.diag_setting);
	  if (this.diag_setting.expert.professional_name.length > 0) {
		this.diag_setting.expert.professional_name = this.diag_setting.expert.professional_name.split(
		  ","
		);
	  }
	  this.$nextTick(() => {
		let index = this.info.diag_setting.expert.depart_id.slice(-1)[0];
		this.symptomLists = this.symptomList.departs.find(
		  (v) => v.id == index
		).symptom;
	  });
	},
	watch: {
	  dialogFormVisible() {
		this.basic_info = this.clone(this.info.basic_info);
	  },
	  setUp() {
		this.diag_setting = this.clone(this.info.diag_setting);
		if (this.diag_setting.expert.professional_name.length > 0) {
		  this.diag_setting.expert.professional_name = this.diag_setting.expert.professional_name.split(
			","
		  );
		}
		this.$nextTick(() => {
		  this.$refs["form2"].validate();
		});
	  },
	},
  
	methods: {
	  uploadApply(res) {
		this.info.basic_info.basic.prac_apply = res.data;
		this.apply_loading = false;
	  },
	  departChange1(e) {
		console.log(e, 222);
		if (e.length == 1) {
		  this.employmentUnit.department_id = e[0];
		}
		if (e.length == 2) {
		  this.employmentUnit.department_id = e[1];
		}
	  },
	  changeZ(row) {
		console.log(row[0]);
		row = row[0];
		this.employmentUnit.title_id = row.value;
		this.employmentUnit.title_name = row.label;
	  },
	  //解约
	  physicianTermination(type) {
		this.$confirm(type == 1 ? "是否解约该医生?" : "是否删除该医生", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			this.fullscreenLoading = true;
			let params = {
			  id: this.id,
			};
			physicianTermination(params)
			  .then((res) => {
				if (res.code == 200) {
				  this.$message({
					type: "success",
					message: type == 1 ? "解约成功!" : "删除成功",
				  });
				  this.$emit(
					"deleteDoc",
					(params = {
					  drawers: false,
					})
				  );
				}
				this.fullscreenLoading = false;
			  })
			  .catch((err) => {
				this.$message({
				  type: "error",
				  message: err.message,
				});
			  });
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: type == 1 ? "已取消解约" : "已取消删除",
			});
		  });
	  },
	  //保存审核
	  saveReviewed() {
		this.fullscreenLoading = true;
		if (this.passReview == "N" && !this.denialReason) {
		  this.$message({
			message: "请填写拒绝原因",
			type: "error",
		  });
		  return;
		}
		if (this.passReview == "Y") {
		  this.denialReason = "";
		}
		let params = {
		  id: this.id,
		  examine: this.passReview,
		  reject_reason: this.denialReason,
		};
		saveReviewed(params).then((res) => {
		  if (res.code == 200) {
			if (this.passReview == "Y") {
			  this.$message({
				message: "审核通过",
				type: "success",
			  });
			} else {
			  this.$message({
				message: "审核已拒绝",
				type: "success",
			  });
			}
		  } else {
			this.$message({
			  message: res.message,
			  type: "error",
			});
		  }
		  this.reviewed = false;
		  this.fullscreenLoading = false;
		  this.closeDrawer();
		});
	  },
	  closeDrawer() {
		let drawers = false;
		this.$emit("closeDrawer", drawers);
	  },
	  editorialWork(item) {
		this.addWork = true;
	  },
	
	  //保存设置
	  saveSet() {
		this.fullscreenLoading = true;
		this.$refs["form2"].validate((valid, obj) => {
		  if (valid) {
			if (
			  this.diag_setting.setting.free_status == 0 &&
			  this.diag_setting.setting.pic_status == 1 &&
			  this.doctorType == 1
			) {
			  if (!this.diag_setting.setting.pic_price) {
				this.$message({
				  message: "请输入图文复诊价格",
				  type: "error",
				});
				this.fullscreenLoading = false;
				return false;
			  }
			  if (!this.diag_setting.setting.pic_num) {
				this.$message({
				  message: "请输入图文复诊上限",
				  type: "error",
				});
				this.fullscreenLoading = false;
				return false;
			  }
			}
			if (
			  this.diag_setting.setting.free_status == 0 &&
			  this.diag_setting.setting.video_status == 1 &&
			  this.doctorType == 1
			) {
			  if (!this.diag_setting.setting.video_price) {
				this.$message({
				  message: "请输入视频复诊价格",
				  type: "error",
				});
				return false;
			  }
			  if (!this.diag_setting.setting.video_num) {
				this.$message({
				  message: "请输入视频复诊上限",
				  type: "error",
				});
				return false;
			  }
			  this.fullscreenLoading = false;
			}
			if (this.doctorType == 1) {
			  let params = {
				id: this.id,
				...this.diag_setting.expert,
				...this.diag_setting.setting,
			  };
  
			  // params.professional = this.diag_setting.expert.professional_code
			  delete params.professional_name;
			  delete params.doc_dept_name;
			  if (params.doc_dept_code) {
				delete params.doc_dept_code;
			  }
			  admissionsSettings(params)
				.then((res) => {
				  if (res.code == 200) {
					this.getInfo("接诊设置修改成功");
				  } else {
					this.$message({
					  message: res.message,
					  type: "error",
					});
				  }
				})
				.catch((err) => {
				  this.$message({
					message: err.message,
					type: "error",
				  });
				});
			} else {
			  let params = {
				id: this.id,
				doc_comment: this.diag_setting.expert.doc_comment,
			  };
			  updateIntroduction(params)
				.then((res) => {
				  if (res.code == 200) {
					this.getInfo("个人简介修改成功");
				  } else {
					this.$message({
					  message: res.message,
					  type: "error",
					});
				  }
				})
				.catch((err) => {
				  this.$message({
					message: err.message,
					type: "error",
				  });
				});
			}
			this.setUp = false;
		  } else {
			let item;
			for (let key in obj) {
			  item = obj[key][0];
			  break;
			}
			this.fullscreenLoading = false;
			this.$message({
			  type: "error",
			  message: item.message,
			});
  
			return false;
		  }
		});
	  },
	  getInfo(typeText) {
		let params = {
		  id: this.id,
		};
		getDoctorsDetails(params)
		  .then((response) => {
			if (response.code == 200) {
			  this.doctorDic.prac_type.forEach((item) => {
				if (item.value == response.data.basic_info.basic.prac_type) {
				  response.data.basic_info.basic.prac_level_label = item.label;
				}
			  });
			  this.info = {
				...response.data,
			  };
			  if (!this.info.basic_info.basic.title_rec_date) {
				this.info.basic_info.basic.title_rec_date = "";
			  }
			  if (!this.info.material.title_cert_list) {
				this.info.material.title_cert_list = {};
			  }
			  if (!this.info.material.employ_files_list) {
				this.info.material.employ_files_list = {};
			  }
			  this.$message({
				message: typeText,
				type: "success",
			  });
			}
			this.fullscreenLoading = false;
			this.dialogFormVisible = false;
		  })
		  .catch((error) => {
			this.$message({
			  message: error.message,
			  type: "error",
			});
			this.fullscreenLoading = false;
			this.dialogFormVisible = false;
		  });
	  },
	  //基础信息保存
	  informationSave() {
		this.fullscreenLoading = true;
		if (this.basic_info.basic.doctor_name.length < 2) {
		  this.$message({
			message: "姓名必须为两字以上汉字",
			type: "error",
		  });
		  this.fullscreenLoading = false;
		  return;
		}
		this.$refs["form1"].validate((valid, obj) => {
		  if (valid) {
			let params = {
			  id: this.id,
			  ...this.basic_info.basic,
			};
			
			delete params.title_name;
			params.ge_code == 1
			  ? (params.ge_name = "男")
			  : (params.ge_name = "女");
			this.dialogFormVisible = true;
			updateBasicInfo(params)
			  .then((res) => {
				if (res.code == 200) {
				  this.getInfo("基础信息修改成功");
				} else {
				  this.$message({
					message: res.message,
					type: "error",
				  });
				  this.fullscreenLoading = false;
				}
			  })
			  .catch((err) => {
				this.fullscreenLoading = false;
				this.dialogFormVisible = false;
				this.$message({
				  message: err.message,
				  type: "error",
				});
			  });
		  } else {
			let item;
			for (let key in obj) {
			  item = obj[key][0];
			  break;
			}
			this.fullscreenLoading = false;
			this.$message({
			  type: "error",
			  message: item.message,
			});
			return false;
		  }
		});
	  },
	  clone(obj) {
		return JSON.parse(JSON.stringify(obj));
	  },
	  mouseLeave() {
		this.mouseOverImg = false;
	  },
	  mouseOver() {
		this.mouseOverImg = true;
	  },
	  //就诊设置
	  openSetUp() {
		this.setUp = true;
  
		this.$nextTick(() => {
		  this.$refs["form2"].validate();
		});
	  },
	  passReviewed(type) {
		this.passReview = type;
	  },
	  //审核
	  review() {
		this.reviewed = true;
	  },
	  //编辑个人信息
	  edit(type) {
		this.dialogFormVisible = true;
		this.editTitle = "编辑" + type;
		this.$nextTick(() => {
		  this.$refs["form1"].validate();
		});
	  },
	  remoteMethod(query) {
		if (query !== "") {
		  this.selectLoading = true;
		  let params = {
			page_size: 999,
			keywords: query,
		  };
		  getOrgList(params).then((res) => {
			if (res.code == 200) {
			  this.orgOptions = res.data.list;
			} else {
			  this.orgOptions = [];
			}
			this.selectLoading = false;
		  });
		} else {
		  this.orgOptions = [];
		}
	  },
	  orgSelectChange(e) {
		this.basic_info.basic.org_id = e.org_id;
		this.basic_info.basic.work_inst_name = e.org_name;
	  },
	  getToken() {
		getQNToken()
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  this.qiniuData.token = data.data.token;
			}
		  })
		  .catch((error) => {
			console.log(error);
		  });
	  },
	  handleChange(value) {
		this.basic_info.basic.district_code = value[2];
		this.basic_info.basic.city_code = value[1];
		this.basic_info.basic.province_code = value[0];
	  },
	  geChange(value) {
		for (let item of this.doctorDic.gender) {
		  if (item.value == value) {
			this.info.ge_name = item.label;
		  }
		}
	  },
	  uploadDocPhotoSuccess(res) {
		this.info.basic_info.basic.doc_photo = res.data;
		this.avatar_loading = false;
	  },

	  uploadHetongASuccess(res) {
		this.info.basic_info.basic.employ_files_list[0] = res.data;
		this.contract_a_loading = false;
	  },
	  uploadHetongBSuccess(res) {
		this.info.basic_info.basic.employ_files_list[1] = res.data;
		this.contract_b_loading = false;
	  },

	  
	  beforeAvatarUpload(file, type, name) {
		switch (type) {
		  case "apply":
			this.apply_loading = true;
			break;
		  case "id_back":
			this.id_back_loading = true;
			break;
		  case "cert_no":
			this.cert_no_loading = true;
			break;
		  case "cert_info":
			this.cert_info_loading = true;
			break;
		  case "prac_no":
			this.prac_no_loading = true;
			break;
		  case "prac_info":
			this.prac_info_loading = true;
			break;
		  case "title_no":
			this.title_no_loading = true;
			break;
		  case "title_info":
			this.title_info_loading = true;
			break;
		  case "contract_a":
			this.contract_a_loading = true;
			break;
		  case "contract_b":
			this.contract_b_loading = true;
			break;
		  case "graduate_cert":
			this.graduate_cert_loading = true;
			break;
		  case "degree_cert":
			this.degree_cert_loading = true;
			break;
		  default:
			this.avatar_loading = true;
		}
		this.qiniuData.type = type;
		this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
		// debugger
		const isJPG = file.type === "image/jpeg" || file.type === "image/png";
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isJPG) {
		  this.$message.error("https://pic.hngegejk.cn上传图片只能是 JPG 格式!");
  
		  return;
		}
		if (!isLt2M) {
		  this.$message.error("上传图片大小不能超过 2MB!");
		  return;
		}
		return isJPG && isLt2M;
	  },
	  departChange(e) {
		console.log(e);
		let index = e.slice(-1);
		this.diag_setting.expert.professional = [];
		this.symptomLists = this.symptomList.departs.find(
		  (v) => v.id == index
		).symptom;
		// this.departSymptom.forEach(item => {
		// 	if (item.id == e[0]) {
		// 		this.symptomLists = item.children
		// 	}
		// })
		// for (let item of this.departSymptom.departs) {
		// 	if (e == item.id) {
		// 		this.symptomList = item.symptom
		// 	}
		// }
	  },
	},
  };
  </script>
  
  <style scoped lang="scss">
  .gg-container {
	box-sizing: border-box;
	margin: 0;
  }
  
 ::v-deep .el-tabs__content {
  }
  
 ::v-deep .el-tab-pane {
	height: 650px;
	overflow: auto;
  }
  
 ::v-deep .el-dialog {
	margin-top: 222px !important;
	margin-left: 40% !important;
	width: 40%;
  }
  
  .caption {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
	color: #111;
	font-size: 16px;
	height: 30px;
	margin-bottom: 10px;
  }
  
 ::v-deep.el-dialog__header {
	padding: 10px 20px 5px 20px;
  
	span {
	  font-size: 14px;
	  font-weight: bold;
	}
  }
  
  .el-dropdown {
	height: 20px !important;
  }
  
  .el-dropdown:hover {
	cursor: pointer !important;
  }
  
  .el-dropdown-link {
	display: inline-block;
	width: 30px;
	height: 20px;
	border: 1px solid #dfe4ed;
	text-align: center;
	line-height: 10px;
  }
  
  .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 1000;
	background-color: white;
  }
  
  .title-dep {
	display: flex;
	align-items: center;
	font-size: 14px;
  
	.title-dep-l {
	  background-color: #eceef2;
	  display: flex;
	  align-items: center;
	  margin-left: 14px;
	  padding: 0 4px;
	}
  
	.title-dep-r {
	  background-color: #eceef2;
	  display: flex;
	  align-items: center;
	  margin-left: 4px;
	  padding: 0 4px;
	}
  }
  
  .edit-dialog {
	min-height: 20vh;
	max-height: 40vh;
	overflow: auto;
  
	.el-dialog__body {
	  padding: 0 20px 30px !important;
	}
  
	.ipt {
	  width: 80%;
	}
  
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
	  width: 80%;
	}
  }
  
  .flex {
	display: flex;
	align-items: center;
  }
  
  .review {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #dfe4ed;
	margin-left: 8px;
  }
  
  .edit:hover {
	cursor: pointer;
  }
  
  .pass-review {
	width: 15px;
	height: 15px;
	background-color: #2362fb;
	border: 1px solid #dfe4ed;
	margin-left: 8px;
  }
  
  .edit-frame {
	height: 60px;
  }
  
  .requires {
	display: block;
	padding-left: 10px;
	position: relative;
	margin-bottom: 5px;
	margin-top: 8px;
  }
  .title_label{
	display: block;
	padding-left: 10px;
	position: relative;
	margin-bottom: 5px;
	margin-top: 8px;
  }
  
  .requires + span {
	color: red;
	display: inline-block;
	margin-bottom: 8px;
  }
  
  .requires::before {
	content: "";
	font-size: 30rpx;
	font-weight: 600;
	color: #e02020;
	position: absolute;
	left: 0;
	top: 2px;
	display: flex;
	align-items: center;
  }
  
  .require {
	display: block;
	padding-left: 10px;
	position: relative;
	margin-bottom: 5px;
	margin-top: 8px;
  }
  
  .require + span {
	color: red;
	display: inline-block;
	margin-bottom: 8px;
  }
  
  .require::before {
	content: "*";
	font-size: 30rpx;
	font-weight: 600;
	color: #e02020;
	position: absolute;
	left: 0;
	top: 2px;
	display: flex;
	align-items: center;
  }
  
  .inb {
	display: inline-block;
	margin-left: 10px;
  }
  
  .title {
	display: flex;
  
	.title-detail {
	  margin-left: 10px;
	  line-height: 30px;
  
	  .title-name {
		span:nth-child(1) {
		  font-weight: bold;
		  color: #111;
		  font-size: 16px;
		}
  
		span:nth-child(2) {
		  display: inline-block;
		  font-weight: 400;
		  margin-left: 8px;
		  color: #6b6b6b;
		  font-size: 14px;
		}
	  }
	}
  }
  
  .upload-img-edit {
	background-color: #d1d1d1;
	width: 220px;
	height: 110px;
	box-shadow: 0 0 3px #999;
	border-radius: 5px;
	margin: 20px 0 20px 5px;
	text-align: center;
	font-size: 14px;
	position: relative;
  
	img {
	  display: inline-block;
	  margin-top: 20px;
	}
  
	.positionImg {
	  margin: 0;
	  position: absolute;
	  top: 50%;
	  left: 39%;
	  width: 50px;
	  height: 50px;
	  transform: translateX(-80px);
	  transform: translateY(-25px);
	  opacity: 0.7;
	}
  }
  
  .avatar-down {
	width: 100%;
	height: 20px;
	background-color: white;
	position: absolute;
	bottom: 0;
	text-align: right;
	padding: 2px 5px;
  
	img {
	  width: 15px;
	  margin: 0;
	}
  }
  
  .upload-img {
	width: 220px;
	height: 110px;
	box-shadow: 0 0 3px #999;
	border-radius: 5px;
	margin: 20px 0 20px 5px;
	text-align: center;
	font-size: 14px;
  
	img {
	  display: inline-block;
	  margin-top: 20px;
	}
  }
  
  .information-info-b {
	margin-bottom: 15px;
  }
  
  .inquiry {
	font-size: 14px;
	font-weight: bold;
  
	.inquiryImg {
	  display: flex;
	  align-items: center;
  
	  img {
		width: 20px;
		height: 20px;
	  }
  
	  span {
		display: inline-block;
		margin-left: 8px;
	  }
	}
  
	.inquiryText {
	  span {
		display: inline-block;
		width: 120px;
	  }
  
	  span:nth-child(2n-1) {
		color: #acafb0;
	  }
  
	  span:nth-child(2) {
		color: red;
	  }
  
	  span:nth-child(4) {
		color: #646464;
	  }
	}
  }
  
  .btn-padding {
	padding: 7px 8px;
	font-size: 12px;
	border: 0;
  }
  
  .w-50 {
	width: 50%;
  }
  
 ::v-deep.el-textarea__inner {
	height: 180px !important;
  }
  
  .m-t-10 {
	margin-top: 10px;
  }
  
  .information-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding: 16px 0 6px 0;
  }
  
  .information-info {
	display: flex;
  
	.information-info-l {
	  width: 50%;
	  font-size: 14px;
  
	  span:nth-child(1) {
		height: 30px;
		line-height: 30px;
		color: #777;
		display: inline-block;
		width: 120px;
	  }
  
	  span:nth-child(2) {
		color: #000;
	  }
	}
  }
  
  .required {
	display: inline-block;
	width: 4px;
	height: 14px;
	background-color: #2362fb;
	position: absolute;
	top: 20px;
  }
  
  .required + span {
	display: inline-block;
	margin-left: 8px;
	vertical-align: top;
	font-weight: bold;
	font-size: 14px;
  }
  
  .avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
  }
  
  .avatar-uploader .el-upload:hover {
	border-color: #409eff;
  }
  
  .avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 298px;
	height: 178px;
	line-height: 178px;
	text-align: center;
  }
  
  .avatars {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: block;
	box-sizing: border-box;
  }
  
  .avatar {
	width: 330px;
	height: 178px;
	display: block;
  }
  
  .elUpload {
	text-align: center;
	position: relative;
  
	.avatar-uploader {
	  border: 1px dotted #666;
	}
  
	.el-upload {
	  width: 100%;
  
	  .avatar-uploader-icon {
		width: 100%;
	  }
	}
  
	.avatar {
	  max-width: 100%;
	}
  
	.del {
	  position: absolute;
	  top: 0;
	  right: 0;
	  color: #fff;
	  font-size: 30px;
	  color: red;
	  cursor: pointer;
	}
  }
  
  .addExperience {
	border: 1px solid #f5f5f5;
	color: #a7a7a7;
	text-align: center;
  }
  
  .addExperience:hover {
	cursor: pointer;
  }
  
  .docPhoto {
	width: 160px;
  }
  
  .el-date-editor.el-input {
	width: auto;
  }
  
  .el-range-editor--small.el-input__inner {
	width: 100%;
  }
  
  .box-card {
	position: relative;
  
	.delBtn {
	  position: absolute;
	  top: 10px;
	  right: 10px;
	  z-index: 9;
	}
  }
  
  .search-container {
	max-height: 40px;
  }
  
  .htList {
	margin-top: 10px;
	background: #f0f0f0;
	border-radius: 5px;
	list-style: none;
	padding: 10px;
	position: relative;
  
	i {
	  cursor: pointer;
	}
  }
  
 ::v-deep.switch .el-switch__label {
	position: absolute;
	display: none;
	color: #fff !important;
  }
  
 ::v-deep.switch {
	margin-left: 8px;
  }
  
  /*打开时文字位置设置*/
 ::v-deep.switch .el-switch__label--right {
	z-index: 1;
  }
  
  /* 调整打开时文字的显示位子 */
 ::v-deep.switch .el-switch__label--right span {
	margin-left: -5px;
	// margin-right: 40px;
  }
  
  /*关闭时文字位置设置*/
 ::v-deep.switch .el-switch__label--left {
	z-index: 1;
  }
  
  /* 调整关闭时文字的显示位子 */
 ::v-deep.switch .el-switch__label--left span {
	margin-left: 30px;
  }
  
  /*显示文字*/
 ::v-deep.switch .el-switch__label.is-active {
	display: block;
  }
  
  /* 调整按钮的宽度 */
 ::v-deep.switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
	width: 50px !important;
	margin: 0;
  }
  
  .timeline {
	position: absolute;
	top: 50px;
	left: 90px;
	width: 50%;
  }
  
  .companydel {
	color: #333;
	font-weight: bold;
	position: absolute;
	left: -125px;
	top: 4px;
	font-size: 12px;
	text-align: center;
  
	p {
	  margin: 0;
	  padding: 0;
	}
  }
  
  .fl {
	float: left;
  }
  
  .fr {
	float: right;
  }
  
  .mouseOver {
	position: absolute;
	width: 68px;
	height: 68px;
	top: -4px;
	left: -4px;
	opacity: 0.6;
  }
  
  .mouseOverAvatar {
	opacity: 0.5;
  }
  
  .el-row {
	span:nth-child(2) {
	  margin-left: 10px;
	}
  
	span:nth-child(3) {
	  margin-left: 10px;
	}
  
	span:nth-child(4) {
	  margin-left: 10px;
	  color: red;
	}
  }
  
  .el-form-item {
	margin-bottom: 0;
  }
  
  .contract {
	position: relative;
	color: #0091ff;
	padding-top: 15px;
	font-size: 16px;
  
	img {
	  display: inline-block;
	  left: 68px;
	  position: absolute;
	  top: 15px;
	}
  }
  
  .close-drawer {
	border: 0;
	width: 30px;
	height: 35px;
	box-sizing: border-box;
	background-color: #ff3a3c;
	color: white;
	position: fixed;
	left: 24%;
	text-align: center;
  
	top: 280px;
	transform: translateX(-30px);
	padding: 0;
	border-radius: 0;
  }
  
  .close-drawer::before {
	content: "×";
	font-size: 30px;
	font-weight: 200;
  }
  </style>
  